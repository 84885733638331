import { Trans } from '@lingui/macro';
import { Box, BoxProps, Button, CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { TxStateType, useModalContext } from 'src/hooks/useModal';
import { useWeb3Context } from 'src/libs/hooks/useWeb3Context';
import { TxAction } from 'src/ui-config/errorMapping';

interface TxPositionActionsWrapperProps extends BoxProps {
  actionInProgressText: ReactNode;
  actionText: ReactNode;
  handleAction: () => Promise<void>;
  isWrongNetwork: boolean;
  mainTxState: TxStateType;
  noFees?: boolean;
  chooseLeverage?: boolean;
  preparingTransactions: boolean;
  requiresAmount?: boolean;
  fetchingData?: boolean;
  variantPositive?: boolean;
  disabled?: boolean;
  disabledLoading?: boolean;
  errorParams?: {
    loading: boolean;
    disabled: boolean;
    content: ReactNode;
    handleClick: () => Promise<void>;
  };
}

export const TxPositionActionsWrapper = ({
  actionInProgressText,
  actionText,
  handleAction,
  isWrongNetwork,
  mainTxState,
  preparingTransactions,
  requiresAmount,
  variantPositive = true,
  fetchingData = false,
  disabled: disabledOuter,
  disabledLoading,
  errorParams,
  chooseLeverage,
  noFees,
  sx,
  ...rest
}: TxPositionActionsWrapperProps) => {
  const { txError } = useModalContext();
  const { readOnlyModeAddress } = useWeb3Context();

  function getMainParams() {
    if (
      (txError?.txAction === TxAction.GAS_ESTIMATION ||
        txError?.txAction === TxAction.MAIN_ACTION) &&
      txError?.actionBlocked || noFees
    ) {
      if (errorParams) return errorParams;
      return { loading: false, disabled: true, content: actionText };
    }
    if (isWrongNetwork) return { disabled: true, content: <Trans>Wrong Network</Trans> };
    if (fetchingData) return { disabled: true, content: <Trans>Fetching data...</Trans> };
    if (preparingTransactions) return { disabled: true, loading: true };
    if (chooseLeverage) return { disabled: true, content: <Trans>Choose Leverage</Trans> };
    if (mainTxState?.loading && !disabledLoading)
      return { loading: true, disabled: true, content: actionInProgressText };
    return { content: actionText, handleClick: handleAction };
  }

  const { content, disabled, loading, handleClick } = getMainParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, ...sx }} {...rest}>
      <Button
        variant="contained"
        disabled={disabled || disabledOuter || readOnlyModeAddress !== undefined}
        onClick={handleClick}
        size="large"
        sx={variantPositive ? positiveButtonMixin : negativeButtonMixin}
      >
        {loading && !disabledLoading && <CircularProgress color="inherit" size="16px" sx={{ mr: 2 }} />}
        {content}
      </Button>
      {readOnlyModeAddress && (
        <Typography variant="helperText" color="warning.main" sx={{ textAlign: 'center', mt: 2 }}>
          <Trans>Read-only mode. Connect to a wallet to perform transactions.</Trans>
        </Typography>
      )}
    </Box>
  );
};

const positiveButtonMixin: SxProps<Theme> = {
  minHeight: '44px',
  backgroundColor: '#AFF113',
  color: '#074245',
  '&:disabled': {
    color: '#074245',
    backgroundColor: '#AFF113',
    opacity: '0.5',
  },
  '&:hover': {
    backgroundColor: '#cafc53',
  }
}

const negativeButtonMixin: SxProps<Theme> = {
  minHeight: '44px',
  color: '#F1F1F3',
  border: '1px solid var(--stroke-6, rgba(255, 255, 255, 0.06))',
  backgroundColor: '#2A2A32',
  '&:hover': {
    backgroundColor: '#363641',
    border: '1px solid var(--stroke-6, rgba(255, 255, 255, 0.08))',
  }
}
