import { FC } from 'react';
import { Box } from '@mui/material';

export interface IScrollModalHeaderProps {
  withDivider?: boolean;
}

export const ScrollModalHeader: FC<IScrollModalHeaderProps> = ({ children, withDivider }) => (
  <Box sx={{
    gridArea: 'header',
    px: 6,
    pb: 4,
    borderBottom: withDivider ? '1px solid rgba(255, 255, 255, 0.08)' : 'unset',
  }}>
    {children}
  </Box>
);
