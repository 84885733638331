import { Box, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';

export interface IScrollModalContentProps {
  sx?: SxProps<Theme>;
}

export const ScrollModalContent: FC<IScrollModalContentProps> = ({ children, sx }) => (
  <Box sx={{
    gridArea: 'content',
    maxHeight: 'calc(100vh - 140px)',
    position: 'relative',
    overflowY: 'auto',
    px: 6,
    ...sx,
  }}>
    {children}
  </Box>
);
