import { FC } from 'react';
import { Box } from '@mui/material';

export const ScrollModalFooter: FC = ({ children }) => (
  <Box sx={{
    gridArea: 'footer',
    px: 6,
    pt: 2,
  }}>
    {children}
  </Box>
);
